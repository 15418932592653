import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'login',
    component: () => import('../views/Login/Login.vue')
  },
  {
    path: '/Home',
    name: 'home',
    // Advices
    redirect: 'Todo',
    component: () => import('../views/Homepage/Home.vue'),
    children: [
      {
        path: '/Todo',
        name: 'todo',
        component: () => import('../views/Takecharge/Takecharge.vue')
      },
      {
        path: '/Linkman',
        name: 'linkman',
        component: () => import('../views/Linkman/Linkman.vue'),
        children: [{
          path: '/Cluster',
          name: 'cluster',
          component: () => import('../views/Linkman/Cluster.vue'),
        },
        {
          path: '/Addsite',
          name: 'addsite',
          component: () => import('../views/Linkman/addsite.vue'),
        },
        {
          path: '/Nom',
          name: 'nom',
          component: () => import('../views/Linkman/Nom.vue'),
        },
        {
          path: '/Section',
          name: 'section',
          component: () => import('../views/Linkman/Section.vue'),
        },
        ]
      }, {
        path: '/Advices',
        name: 'advices',
        component: () => import('../views/Advices/Advices.vue')
      }, {
        path: '/Havedone',
        name: 'havedone',
        component: () => import('../views/Havedone/Havedone.vue')
      }, {
        path: '/Notification',
        name: 'notification',
        component: () => import('../views/Notification/Notification.vue')
      },
      {
        path: '/Cooperation',
        name: 'cooperation',
        component: () => import('../views/Headerone/Cooperation.vue')
      },
      {
        path: '/Notice',
        name: 'notice',
        component: () => import('../views/Headerone/Notice.vue')
      },
      {
        path: '/Takecharge',
        name: 'takecharge',
        component: () => import('../views/Takecharge/Takecharge.vue')
      }, 
      {
        path: '/Person',
        name: 'person',
        component: () => import('../views/Person/Person.vue')
      },
      {
        path: '/Materialpersonal',
        name: 'Materialpersonal',
        component: () => import('../views/Material/personal.vue')
      },
      {
        path: '/Setup',
        name: 'Setup',
        component: () => import('../views/Setup/setup.vue')
      },
    ]
  }
]



const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(to) {
  return VueRouterPush.call(this, to).catch(err => err)
}
const router = new VueRouter({
  mode: 'hash',
  // mode: 'history',
  base: process.env.BASE_URL,
  routes
})


// 路由守卫
router.beforeEach((to, from, next) => {
  var userInfoStr = window.localStorage.getItem('wmtodo_pctask_user_info');
  var userInfo = null;
  if (userInfoStr) {
    userInfo = JSON.parse(userInfoStr);
  }
  // console.log(to, userInfoStr, to.name != 'login' && !userInfoStr)
  if (to.name && to.name != 'login' && !userInfoStr) {
    console.log('去登陆')
    next({//返回登录页面
      path: '/',
    })
  } else if (userInfo && userInfo.token && to.name == 'login') {
    next({//已登录免登陆
      path: '/Home',
    })
  } else {
    if (window._hmt) {
          if (to.path) {
            window._hmt.push(['_trackPageview', '/#' + to.fullPath])
          }
        }
    next();
  }
})
// router.beforeEach((to, from, next) => {
//   if (window._hmt) {
//     if (to.path) {
//       window._hmt.push(['_trackPageview', '/#' + to.fullPath])
//     }
//   }
//   next()
// })
export default router