import Vue from 'vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue'
import router from './router'
import store from './store'
// 日期插件
import dayjs from 'dayjs';
//axios
import axios from 'axios'
Vue.prototype.axios = axios
import './assets/iconi/iconfont.css'
import debounce from '../src/until/debounce'
import VueDragscroll from 'vue-dragscroll'
import VueClipboard from 'vue-clipboard2'
 
Vue.use(VueClipboard)
Vue.use(VueDragscroll)
Vue.mixin(debounce)
Vue.prototype.dayjs = dayjs;
Vue.use(ElementUI);
Vue.config.productionTip = false


import comm from './until/comm';
Vue.prototype.$token_key = comm.token_key;//缓存token的key
ElementUI.MessageBox.setDefaults({
  closeOnClickModal: false
});




new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')